import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout, { layoutContext } from "../components/layout"
import Seo from "../components/seo"
import content from "../contents/heavy-specialty-item-moving.yaml"


const Page = () => {


  return (
    <Layout>
      <layoutContext.Consumer>
        {
          ({ setPopupStatus }) => (
            <>
              <Seo title={content.title} keywords={content.keywords} description={content.description} />
              <div className="l-banner-about">
                <div className="container">
                  <div className="m-auto max-w-[600px] text-center">
                    <h1 className="text-h3 lg:text-h2 font-bold mb-8">{content.title}</h1>
                    <p className="max-w-[450px] m-auto">{content.sub_title}</p>
                  </div>
                </div>
              </div>

              <div className="container mt-16">
                <div className="grid lg:grid-cols-2">
                  <div className="lg:order-2">
                    <StaticImage src="../images/long_moving_1.jpg" />
                  </div>

                  <div className="lg:order-1 mt-16 lg:mt-0">
                    {content.features.map(feature =>
                      <div className="m-services-box mb-12 pl-12">
                        <h3 className="text-h3 font-semibold mb-3">{feature.title}</h3>
                        <p class="lg:max-w-[408px]">{feature.desc}</p>
                      </div>
                    )}

                  </div>
                </div>
              </div>


              <div className="container">
                <h2 className="text-h2 font-bold text-center mb-[72px] lg:mt-[157px] max-w-[890px] m-auto">{content.bottom_title}</h2>
                <div className="grid lg:grid-cols-2">
                  <div>
                    <StaticImage src="../images/long_moving_2.jpg" />
                  </div>
                  <div className="mt-8 lg:mt-0 text-center lg:text-left">
                    {content.bottom_desc.map(paragraph =>
                      <p className="mb-4">{paragraph}</p>
                    )}
                  </div>
                </div>

              </div>





            </>
          )
        }
      </layoutContext.Consumer>





    </Layout>
  )
}


export default Page
